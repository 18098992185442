import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import translate from "../utils/translation"
import styles from "./photo.module.scss"


class PhotoTemplate extends React.Component {

  render() {
    const post = this.props.data.markdownRemark
    const lang = post.fields.slug.split('/')[1]
    const { parent, altPost } = this.props.pageContext
    const siteTitle = translate('site.name', lang)
    const headerSubTitle = parent ? parent.frontmatter.title : translate('site.description', lang)
    const backPath = parent ? parent.fields.slug : `/${lang}/`

    return (
      <Layout location={this.props.location} title={siteTitle} subTitle={headerSubTitle} slug={post.fields.slug} hasLanguage={!!altPost}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          lang={lang}
          slug={post.fields.slug}
        />
        <article className="main-content">
          <div className={styles.container}>
            <div className={`${styles.photos} columns is-desktop is-multiline`}>
              {
                post.frontmatter.images.map(
                  (im, i) => {
                    const horizontal = !post.frontmatter.horizontals ? false : post.frontmatter.horizontals.indexOf(i) > -1
                    return <div key={i} className={`${styles.photo} column ${horizontal ? `is-full ${styles.horizontal}` : 'is-half'}`}>
                      <img src={im} alt={post.frontmatter.photoTitle}></img>
                    </div>
                  }
                )
              }
            </div>
            <h1 className={styles.title}>{post.frontmatter.title}</h1>
            <div className={styles.caption}>
            {
              post.frontmatter.captions.map(
                (c, i) => <span key={i} className={styles.captionItem}>{c}</span>
              )
            }
            </div>
            <p className={styles.description}>{post.frontmatter.description}</p>
            <div className={styles.credit}>
            {
              post.frontmatter.credits.map(
                (c, i) => <span key={i} className={styles.creditItem}>{c}</span>
              )
            }
            </div>
            {
              parent && (
                <div className={styles.control}>
                  <Link rel="back"
                    to={backPath}
                    style={{
                      boxShadow: `none`,
                      textDecoration: `none`,
                      color: `inherit`,
                    }}>
                    <button>Back</button>
                  </Link>
                </div>
              )
            }
          </div>
        </article>
      </Layout>
    )
  }
}

export default PhotoTemplate

export const pageQuery = graphql`
  query PhotoBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        images
        credits
        captions
        horizontals
      }
    }
  }
`
